import { CLink } from '@/CLink';
import { LogoLine } from '@/LogoLine';
import { ReactComponent as FaxIcon } from '@a/icons/fax.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { siteInfo } from './NavLinks';
import { flexCenter, flexSpace, flexStart, px, py } from './styles/classes';

const Section = styled.section`
    ${px};
    ${py};

    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

const Info = styled.div`
    margin-bottom: 40px;
    max-width: 488px;

    > h2 {
        margin: 8px 0 28px;
    }

    > p:first-of-type,
    > a {
        ${flexStart};
        align-items: center;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        margin-bottom: 20px;
        word-break: break-word;
        max-width: max-content;

        > span {
            ${flexCenter};
            flex-shrink: 0;
            margin-right: 24px;
            width: 32px;
            height: 32px;
            background-color: ${({ theme }) => theme.colors.blue1};
            border-radius: 5px;
            transition: background-color 0.3s ease-in-out;

            > svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;

                path {
                    fill: ${({ theme }) => theme.colors.blue5};
                    transition: fill 0.3s ease-in-out;
                }
            }
        }

        :last-of-type {
            margin-bottom: 28px;
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue4};

            > span {
                background-color: #deedfb;

                > svg path {
                    fill: ${({ theme }) => theme.colors.blue4};
                }
            }
        }
    }

    > p:first-of-type {
        text-decoration: none;
    }

    > p:not(:first-of-type) {
        ${flexSpace};
        margin: 0 0 16px;

        span {
            text-align: right;

            :first-of-type {
                font-weight: 700;
            }
        }

        :nth-last-of-type(2),
        :last-of-type {
            > span:last-of-type {
                color: ${({ theme }) => theme.colors.gray4};
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin: 0;
        min-width: 330px;
        margin-right: 5.55vw; //80px at 1440px
    }
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    margin-bottom: -4px;
    min-height: 200px;
    height: 50vw;
    max-width: 630px;
    max-height: 630px;
    aspect-ratio: 1/1;
`;

export const HoursMap = () => {
    return (
        <Section>
            <Info>
                <LogoLine />
                <h2>Contact Information</h2>
                <p>
                    <span>
                        <PinIcon />
                    </span>
                    {siteInfo.address.text}
                </p>
                <CLink to={siteInfo.phone.link}>
                    <span>
                        <PhoneIcon />
                    </span>
                    {siteInfo.phone.text}
                </CLink>
                <CLink to={siteInfo.fax.link}>
                    <span>
                        <FaxIcon />
                    </span>
                    {siteInfo.fax.text}
                </CLink>
                <p>
                    <span>Monday:</span>
                    <span>8:00 am - 4:00 pm</span>
                </p>
                <p>
                    <span>Tuesday:</span>
                    <span>8:00 am - 4:00 pm</span>
                </p>
                <p>
                    <span>Wednesday:</span>
                    <span>8:00 am - 4:00 pm</span>
                </p>
                <p>
                    <span>Thursday:</span>
                    <span>8:00 am - 4:00 pm</span>
                </p>
                <p>
                    <span>Friday:</span>
                    <span>8:00 am - 12:00 pm</span>
                </p>
                <p>
                    <span>Saturday:</span>
                    <span>Closed</span>
                </p>
                <p>
                    <span>Sunday:</span>
                    <span>Closed</span>
                </p>
            </Info>

            <Iframe
                title="dallas periodontics location on map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.4914635970017!2d-96.76584782368656!3d32.88517197844971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9fe586f9abc3%3A0x1bb47834ccdefc!2s7557%20Rambler%20Rd%20%231465%2C%20Dallas%2C%20TX%2075231%2C%20USA!5e0!3m2!1sen!2s!4v1692184329018!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></Iframe>
        </Section>
    );
};
