import { css } from '@emotion/react';

const styles = css`
    line-height: 152.5%;
    font-weight: 700;
    font-family: 'Rufina', serif;
`;

export const h1 = css`
    ${styles};
    font-size: 2rem;

    @media (min-width: 1280px) {
        font-size: 3.5rem;
    }
`;

export const h2 = css`
    ${styles};
    font-size: 1.625rem;
    text-transform: uppercase;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
    }
`;

export const h3 = css`
    ${styles};
    font-size: 1.25rem;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h4 = css`
    font-size: 1.25rem;
    line-height: 134.6%;
    font-weight: 700;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;

    @media (min-width: 1280px) {
        font-size: 1rem;
    }
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;
