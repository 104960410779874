import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactForm } from './ContactForm';
import { flexCenter, px, py } from './styles/classes';

const Section = styled.section`
    ${px};
    ${py};
    position: relative;
    isolation: isolate;

    > .gatsby-image-wrapper:last-of-type {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

const Wrapper = styled.div`
    position: relative;
    isolation: isolate;
    padding-bottom: 17px;
    padding-right: 15px;
    max-width: max-content;
    margin-top: 40px;

    > .gatsby-image-wrapper:last-of-type {
        position: absolute;
        top: 17px;
        left: 15px;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    @media (min-width: 1024px) {
        order: -1;
        flex-shrink: 0.4;
        margin-right: ${({ theme }) => theme.spacing.xxl};
    }

    @media (min-width: 1280px) {
        padding-bottom: 28.5px;
        padding-right: 25px;

        > .gatsby-image-wrapper:last-of-type {
            top: 28.5px;
            left: 25px;
        }
    }
`;

export const ContactUs = () => {
    return (
        <Section>
            <ContactForm />

            <Wrapper>
                <StaticImage
                    src="../../../assets/images/layout/contact.jpg"
                    alt="dallas perio office"
                    width={565}
                    height={653}
                />
                <StaticImage
                    src="../../../assets/images/cloth-pattern.jpg"
                    alt="marble pattern"
                    width={565}
                    height={653}
                />
            </Wrapper>

            <StaticImage
                src="../../../assets/images/marble-pattern.jpg"
                alt="marble pattern"
                layout="fullWidth"
            />
        </Section>
    );
};
