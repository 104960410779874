import { ContactUs } from '@/layout/ContactUs';
import { Footer } from '@/layout/Footer';
import { HoursMap } from '@/layout/HoursMap';
import { Nav } from '@/layout/Nav';
import { SkipNavContent, SkipNavLink } from '@/layout/SkipNav';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { ThemeProvider } from '@emotion/react';
import { ReactNode } from 'react';

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <SkipNavLink />
            <Nav />
            <SkipNavContent />
            <main>{children}</main>
            <HoursMap />
            <ContactUs />
            <Footer />
        </ThemeProvider>
    );
};
