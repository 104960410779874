import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ white?: boolean }>`
    padding: 16px 28px;
    color: ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue4)};
    box-shadow:
        inset 0 2px 0 ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue4)},
        inset 0 -2px 0 ${({ theme, white }) => (white ? theme.colors.gray1 : theme.colors.blue4)};
    transition: background-color 0.3s ease-in-out;
    text-transform: capitalize;

    > svg {
        margin-right: 12px;
    }

    :hover {
        background-color: rgba(167, 202, 214, 0.3);
    }

    :focus-visible {
        background-color: rgba(167, 202, 214, 0.3);
    }
`;
