// eslint-disable-next-line
import { useLocation } from '@reach/router';

export default function useLocationData() {
    const location = useLocation();
    const url = location.pathname.slice(1, -1).split('/');

    const replace = (text: string) => text.replaceAll('-', ' ');

    const category = url.length >= 2 ? replace(url[0]) : '';
    let subCategory = url.length === 3 ? replace(url[1]) : '';
    const pageTitle =
        url.length === 1 ? url[0] : url.length === 2 ? url[1] : url.length === 3 ? url[2] : '';
    let title = replace(pageTitle);
    title =
        title === 'non surgical periodontal treatment'
            ? 'non-surgical periodontal treatment'
            : title;

    title = title === 'periodontal gum disease' ? 'periodontal / gum disease' : title;
    title = title === 'tooth recession gum grafting' ? 'tooth recession / gum grafting' : title;

    subCategory =
        subCategory === 'non surgical periodontal treatment'
            ? 'non-surgical periodontal treatment'
            : subCategory;

    return { category, subCategory, title, pageTitle };
}
