import { css, Global, Theme } from '@emotion/react';
import '@fontsource/figtree';
import '@fontsource/figtree/600.css';
import '@fontsource/figtree/700.css';
import '@fontsource/rufina';
import '@fontsource/rufina/700.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4 } from './Typography';

export const theme: Theme = {
    colors: {
        gray1: '#fff',
        gray2: '#D3D6D8',
        gray3: '#9EA5A8',
        gray4: '#646A6B',
        gray5: '#232425',
        blue1: '#DEE6EF',
        blue2: '#8BB0E2',
        blue3: '#4A83D2',
        blue4: '#174B92',
        blue5: '#0E2749',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.gray5};
                    font-family: 'Figtree', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 160%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                    overflow: unset !important; // to overcome cherry financing script adding scroll style
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray5};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                button {
                    :disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
