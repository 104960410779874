export const siteInfo = {
    phone: {
        text: '(214) 238-8323',
        link: 'tel:+12142388323',
    },
    fax: {
        text: '(214) 254-4705',
        link: 'tel:+12142544705',
    },
    email: {
        link: '',
        text: '',
    },
    address: {
        text: '7557 Rambler Rd #1465, Dallas, TX 75231',
        link: '',
    },
    social: {
        facebook: '',
        instagram: '',
        twitter: '',
        yelp: '',
        youtube: '',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
        links: [
            {
                text: 'about us',
                link: '/about-us/',
            },
            {
                text: 'meet dr. laura rabe',
                link: '/about-us/meet-dr-laura-rabe/',
            },
            {
                text: 'tour the office',
                link: '/about-us/tour-the-office/',
            },
            {
                text: 'board certified periodontist',
                link: '/about-us/board-certified-periodontist/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
        ],
    },
    {
        text: 'services',
        link: '/services/',
        links: [
            {
                text: 'non-surgical periodontal treatment',
                link: '/services/non-surgical-periodontal-treatment/',
                links: [
                    {
                        text: 'non-surgical periodontal treatment',
                        link: '/services/non-surgical-periodontal-treatment/',
                    },
                    {
                        text: 'scaling and root planing',
                        link: '/services/non-surgical-periodontal-treatment/scaling-and-root-planing/',
                    },
                    {
                        text: 'local and systemic antibiotics ',
                        link: '/services/non-surgical-periodontal-treatment/local-and-systemic-antibiotics/',
                    },
                ],
            },
            {
                text: 'periodontal / gum disease',
                link: '/services/periodontal-gum-disease/',
            },
            {
                text: 'dental implants',
                link: '/services/dental-implants/',
            },
            {
                text: 'full arch implants',
                link: '/services/full-arch-implants/',
            },
            {
                text: 'immediate implants',
                link: '/services/immediate-implants/',
            },
            {
                text: 'bone grafting',
                link: '/services/bone-grafting/',
                links: [
                    {
                        text: 'bone grafting',
                        link: '/services/bone-grafting/',
                    },
                    {
                        text: 'periodontal disease grafting',
                        link: '/services/bone-grafting/periodontal-disease-grafting/',
                    },
                    {
                        text: 'ridge grafting',
                        link: '/services/bone-grafting/ridge-grafting/',
                    },
                    {
                        text: 'sinus bone grafting',
                        link: '/services/bone-grafting/sinus-bone-grafting/',
                    },
                    {
                        text: 'implant grafting',
                        link: '/services/bone-grafting/implant-grafting/',
                    },
                ],
            },
            {
                text: 'tooth recession / gum grafting',
                link: '/services/tooth-recession-gum-grafting/',
            },
            {
                text: 'esthetic treatments',
                link: '/services/esthetic-treatments/',
            },
            {
                text: 'sedation and dental anesthesia',
                link: '/services/sedation-and-dental-anesthesia/',
            },
        ],
    },
    {
        text: 'new patients',
        link: '/new-patients/',
        links: [
            {
                text: 'why see a periodontist',
                link: '/new-patients/why-see-a-periodontist/',
            },
            // {
            //     text: 'before & after',
            //     link: '/new-patients/before-and-after/',
            // },
            // {
            //     text: 'patient forms',
            //     link: '/new-patients/patient-forms/',
            // },
            {
                text: 'schedule an appointment',
                link: 'https://patientportal.carestack.com/?dn=lrabe/#/online-appointments',
            },
        ],
    },
    {
        text: 'Payments & Financing',
        link: '',
        links: [
            {
                text: 'Cherry Financing',
                link: '/cherry-financing/',
            },
            {
                text: 'CareCredit Payments',
                link: 'https://www.carecredit.com/go/665CPS/',
            },
            {
                text: 'Pay Bill Online',
                link: 'https://bestcardteam.com/Payments/?Profile=DallasperioWEB',
            },
        ],
    },
    {
        text: 'referring doctors',
        link: '/referring-doctors/',
    },
    {
        text: 'testimonials',
        link: '/testimonials/',
    },
    {
        text: 'contact us',
        link: '',
        links: [
            {
                text: 'location and hours',
                link: '/contact-us/location-and-hours/',
            },
            {
                text: 'notice of privacy practices',
                link: '/contact-us/notice-of-privacy-practices/',
            },
            {
                text: 'schedule an appointment',
                link: 'https://patientportal.carestack.com/?dn=lrabe/#/online-appointments',
            },
        ],
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: getNavLink('about us'),
    },
    {
        text: 'meet dr. laura rabe',
        link: getNavLink('meet dr. laura rabe'),
    },
    {
        text: 'tour the office',
        link: getNavLink('tour the office'),
    },
    {
        text: 'Payment Plans',
        link: getNavLink('payment plans'),
    },
    {
        text: 'periodontal / gum disease',
        link: getNavLink('periodontal / gum disease'),
    },
    {
        text: 'bone grafting',
        link: getNavLink('bone grafting'),
    },
    {
        text: 'dental implants',
        link: getNavLink('dental implants'),
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
