import { LogoLine } from '@/LogoLine';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Form, Input, Label, Thanks } from '../FormComponents';
import { PrimaryBtn } from '../buttons';

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
        setLoading(false);
        e.target.reset();
    };

    return (
        <Form onSubmit={handleSubmit} submit={submit}>
            <LogoLine />
            <h2>contact us</h2>
            <Label htmlFor="name">
                <span>
                    Name<strong>*</strong>
                </span>
                <Input type="text" id="name" name="name" placeholder="Full Name" required />
            </Label>

            <Label htmlFor="email">
                <span>
                    Email address<strong>*</strong>
                </span>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="Enter your email address"
                />
            </Label>
            <Label htmlFor="phone">
                <span>
                    Phone number<strong>*</strong>
                </span>
                <PatternFormat
                    type="tel"
                    format="+1 (###) ###-####"
                    customInput={Input}
                    placeholder="123 456-7890"
                    id="phone"
                    name="phone"
                    required
                    pattern="^(\+1)[ ](\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$"
                />
            </Label>

            <Label htmlFor="message">
                <span>Message</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={5}
                    placeholder="Write to us..."
                />
            </Label>
            <PrimaryBtn disabled={loading} type="submit" style={{ display: 'flex', width: '100%' }}>
                {loading ? 'Sending...' : 'Submit'}
            </PrimaryBtn>
            <Thanks submit={submit} setSubmit={setSubmit} />
        </Form>
    );
};
