exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-board-certified-periodontist-tsx": () => import("./../../../src/pages/about-us/board-certified-periodontist.tsx" /* webpackChunkName: "component---src-pages-about-us-board-certified-periodontist-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-meet-dr-laura-rabe-tsx": () => import("./../../../src/pages/about-us/meet-dr-laura-rabe.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-dr-laura-rabe-tsx" */),
  "component---src-pages-about-us-tour-the-office-tsx": () => import("./../../../src/pages/about-us/tour-the-office.tsx" /* webpackChunkName: "component---src-pages-about-us-tour-the-office-tsx" */),
  "component---src-pages-cherry-financing-tsx": () => import("./../../../src/pages/cherry-financing.tsx" /* webpackChunkName: "component---src-pages-cherry-financing-tsx" */),
  "component---src-pages-contact-us-location-and-hours-tsx": () => import("./../../../src/pages/contact-us/location-and-hours.tsx" /* webpackChunkName: "component---src-pages-contact-us-location-and-hours-tsx" */),
  "component---src-pages-contact-us-notice-of-privacy-practices-tsx": () => import("./../../../src/pages/contact-us/notice-of-privacy-practices.tsx" /* webpackChunkName: "component---src-pages-contact-us-notice-of-privacy-practices-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-patients-why-see-a-periodontist-tsx": () => import("./../../../src/pages/new-patients/why-see-a-periodontist.tsx" /* webpackChunkName: "component---src-pages-new-patients-why-see-a-periodontist-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referring-doctors-tsx": () => import("./../../../src/pages/referring-doctors.tsx" /* webpackChunkName: "component---src-pages-referring-doctors-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-a-comprehensive-guide-to-sedation-and-dental-anesthesia-options-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/a-comprehensive-guide-to-sedation-and-dental-anesthesia-options/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-a-comprehensive-guide-to-sedation-and-dental-anesthesia-options-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-a-guide-to-sedation-and-dental-anesthesia-for-stress-free-periodontal-care-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/a-guide-to-sedation-and-dental-anesthesia-for-stress-free-periodontal-care/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-a-guide-to-sedation-and-dental-anesthesia-for-stress-free-periodontal-care-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-act-early-to-combat-periodontal-disease-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/act-early-to-combat-periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-act-early-to-combat-periodontal-disease-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-all-about-sedation-and-dental-anesthesia-in-periodontal-treatments-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/all-about-sedation-and-dental-anesthesia-in-periodontal-treatments/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-all-about-sedation-and-dental-anesthesia-in-periodontal-treatments-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-comprehensive-guide-to-bone-grafting-in-periodontal-treatments-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/comprehensive-guide-to-bone-grafting-in-periodontal-treatments/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-comprehensive-guide-to-bone-grafting-in-periodontal-treatments-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-comprehensive-guide-to-gum-recession-causes-treatments-and-gum-grafting-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/comprehensive-guide-to-gum-recession-causes,-treatments,-and-gum-grafting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-comprehensive-guide-to-gum-recession-causes-treatments-and-gum-grafting-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-how-gum-grafts-can-boost-your-smile-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/discover-how-gum-grafts-can-boost-your-smile-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-how-gum-grafts-can-boost-your-smile-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-empower-your-periodontal-disease-treatment-with-the-help-of-local-and-systemic-antibiotics-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/empower-your-periodontal-disease-treatment-with-the-help-of-local-and-systemic-antibiotics/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-empower-your-periodontal-disease-treatment-with-the-help-of-local-and-systemic-antibiotics-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-enhance-your-smile-with-esthetic-treatments-for-periodontal-benefits-and-confidence-boosts-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/enhance-your-smile-with-esthetic-treatments-for-periodontal-benefits-and-confidence-boosts/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-enhance-your-smile-with-esthetic-treatments-for-periodontal-benefits-and-confidence-boosts-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-esthetic-treatments-for-enhanced-smile-confidence-in-periodontics-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/esthetic-treatments-for-enhanced-smile-confidence-in-periodontics/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-esthetic-treatments-for-enhanced-smile-confidence-in-periodontics-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-experience-the-benefits-of-immediate-dental-implants-and-rediscover-your-smile-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/experience-the-benefits-of-immediate-dental-implants-and-rediscover-your-smile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-experience-the-benefits-of-immediate-dental-implants-and-rediscover-your-smile-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-explore-scaling-and-root-planing-for-non-surgical-treatment-of-periodontal-disease-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/explore-scaling-and-root-planing-for-non-surgical-treatment-of-periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-explore-scaling-and-root-planing-for-non-surgical-treatment-of-periodontal-disease-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-exploring-advanced-solutions-for-dental-implants-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/exploring-advanced-solutions-for-dental-implants-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-exploring-advanced-solutions-for-dental-implants-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-frequently-asked-questions-about-dental-implants-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/frequently-asked-questions-about-dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-frequently-asked-questions-about-dental-implants-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-full-arch-dental-implants-your-complete-guide-to-restoring-function-and-aesthetics-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/full-arch-dental-implants-your-complete-guide-to-restoring-function-and-aesthetics/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-full-arch-dental-implants-your-complete-guide-to-restoring-function-and-aesthetics-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-full-arch-implants-vs-immediate-implants-which-is-right-for-you-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/full-arch-implants-vs-immediate-implants-which-is-right-for-you/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-full-arch-implants-vs-immediate-implants-which-is-right-for-you-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-guide-to-choosing-dental-implants-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/guide-to-choosing-dental-implants-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-guide-to-choosing-dental-implants-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-guide-to-sedation-for-dental-procedures-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/guide-to-sedation-for-dental-procedures-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-guide-to-sedation-for-dental-procedures-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-non-surgical-periodontal-treatments-exploring-scaling-and-root-planing-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/non-surgical-periodontal-treatments-exploring-scaling-and-root-planing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-non-surgical-periodontal-treatments-exploring-scaling-and-root-planing-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-non-surgical-periodontal-treatments-understanding-the-options-and-their-benefits-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/non-surgical-periodontal-treatments-understanding-the-options-and-their-benefits/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-non-surgical-periodontal-treatments-understanding-the-options-and-their-benefits-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-replacing-dental-implants-in-dallas-a-guide-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/replacing-dental-implants-in-dallas-a-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-replacing-dental-implants-in-dallas-a-guide-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-restoring-your-smile-with-dental-implants-benefits-procedure-and-aftercare-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/restoring-your-smile-with-dental-implants-benefits-procedure,-and-aftercare/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-restoring-your-smile-with-dental-implants-benefits-procedure-and-aftercare-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-scaling-and-root-planing-the-non-surgical-treatment-for-periodontal-disease-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/scaling-and-root-planing-the-non-surgical-treatment-for-periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-scaling-and-root-planing-the-non-surgical-treatment-for-periodontal-disease-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-sedation-and-dental-anesthesia-overcoming-dental-anxiety-for-periodontal-procedures-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/sedation-and-dental-anesthesia-overcoming-dental-anxiety-for-periodontal-procedures/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-sedation-and-dental-anesthesia-overcoming-dental-anxiety-for-periodontal-procedures-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-solving-failed-dental-implants-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/solving-failed-dental-implants-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-solving-failed-dental-implants-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tackling-tooth-recession-a-comprehensive-guide-to-gum-grafting-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/tackling-tooth-recession-a-comprehensive-guide-to-gum-grafting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tackling-tooth-recession-a-comprehensive-guide-to-gum-grafting-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-advantages-of-immediate-dental-implants-for-tooth-replacement-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/the-advantages-of-immediate-dental-implants-for-tooth-replacement/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-advantages-of-immediate-dental-implants-for-tooth-replacement-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-top-benefits-of-choosing-gum-grafts-for-receding-gums-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/top-benefits-of-choosing-gum-grafts-for-receding-gums/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-top-benefits-of-choosing-gum-grafts-for-receding-gums-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-understanding-non-surgical-periodontal-treatments-scaling-and-root-planing-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/understanding-non-surgical-periodontal-treatments-scaling-and-root-planing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-understanding-non-surgical-periodontal-treatments-scaling-and-root-planing-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-is-a-dental-cleaning-and-why-do-i-need-one-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/what-is-a-dental-cleaning-and-why-do-i-need-one/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-is-a-dental-cleaning-and-why-do-i-need-one-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-to-do-if-your-dental-implant-fails-in-dallas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/what-to-do-if-your-dental-implant-fails-in-dallas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-what-to-do-if-your-dental-implant-fails-in-dallas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-dental-implants-are-a-game-changer-for-missing-teeth-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/blog/why-dental-implants-are-a-game-changer-for-missing-teeth/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-dental-implants-are-a-game-changer-for-missing-teeth-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-implant-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/bone-grafting/implant-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-implant-grafting-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/bone-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-periodontal-disease-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/bone-grafting/periodontal-disease-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-periodontal-disease-grafting-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-ridge-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/bone-grafting/ridge-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-ridge-grafting-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-sinus-bone-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/bone-grafting/sinus-bone-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-bone-grafting-sinus-bone-grafting-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-esthetic-treatments-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/esthetic-treatments/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-esthetic-treatments-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-full-arch-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/full-arch-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-full-arch-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-immediate-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/immediate-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-immediate-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/non-surgical-periodontal-treatment/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-local-and-systemic-antibiotics-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/non-surgical-periodontal-treatment/local-and-systemic-antibiotics/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-local-and-systemic-antibiotics-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-scaling-and-root-planing-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/non-surgical-periodontal-treatment/scaling-and-root-planing/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-non-surgical-periodontal-treatment-scaling-and-root-planing-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-periodontal-gum-disease-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/periodontal-gum-disease/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-periodontal-gum-disease-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-and-dental-anesthesia-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/sedation-and-dental-anesthesia/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-and-dental-anesthesia-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-tooth-recession-gum-grafting-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/dallas-perio/dallas-perio/src/content/services/tooth-recession-gum-grafting/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-tooth-recession-gum-grafting-index-mdx" */)
}

