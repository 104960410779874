import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexSpace } from './layout/styles/classes';

const Span = styled.span<{ doubleLine?: boolean; white?: boolean }>`
    ${flexSpace};

    ::before,
    ::after {
        content: '';
        background-color: ${({ theme }) => theme.colors.gray3};
        height: 1px;
        width: 100%;
    }

    ::before {
        display: ${({ doubleLine }) => (doubleLine ? 'block' : 'none')};
        margin-right: 16px;
    }

    ::after {
        margin-left: 16px;
    }

    > svg {
        flex-shrink: 0;
    }

    ${({ white, theme }) =>
        white &&
        css`
            ::before,
            ::after {
                background-color: ${theme.colors.gray1};
            }

            > svg path {
                fill: ${theme.colors.gray1};
            }
        `};
`;

export const LogoLine = ({ doubleLine, white }: { doubleLine?: boolean; white?: boolean }) => {
    return (
        <Span doubleLine={doubleLine} white={white}>
            <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_214_769)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.16284 31.7285V8.69512H9.28671C13.8532 8.69512 17.5915 12.4335 17.5915 16.9999C17.5915 21.5664 13.8532 25.3047 9.28671 25.3047V27.5643H13.1434C18.952 27.5643 23.7077 22.8085 23.7077 16.9999C23.7077 11.1913 18.952 6.43555 13.1434 6.43555H0V8.70695H0.153793C1.20668 8.78976 2.04662 9.66519 2.04662 10.7417V29.6937C2.04662 30.8176 1.12387 31.7403 0 31.7403V34.0117H8.16284V31.7285Z"
                        fill="#7F9BBC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.16284 31.7286H0V34H17C26.3459 34 34 26.3459 34 17C34 7.65414 26.3459 0 17 0H0V2.2714H13.1434C21.247 2.2714 27.872 8.89631 27.872 17C27.872 25.1037 21.247 31.7286 13.1434 31.7286H8.16284Z"
                        fill="#174B92"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_214_769">
                        <rect width="34" height="34" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Span>
    );
};
